@import "~scss/imports";

.element {
}

.gallery {
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-column-gap: var(--pad);

  .picker {
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    align-items: start;
    margin-top: 0;
  }
  .detail {
    img {
      background-color: var(--slate);
      object-fit: contain;
      width: 100%;
      height: calc(80vh - var(--nav-height));
      border-radius: 5em;
    }
  }
}

.detail {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}

// .toggleGallery {
//   position: absolute;
//   bottom: 1rem;
//   left: 1rem;
//   background-color: var(--white);
//   color: var(--brown);
//   border: 1px solid;
//   font-size: 100%;
//   cursor: pointer;
// }

.picker {
  display: grid;
  grid-gap: 26px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 36px;
}

.pickerItem {
  padding: 0;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  background-color: transparent;
  padding: 3px;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: 0 0 1px 1px var(--brown);
  }
}

.selectedPickerItem {
  @extend .pickerItem;
  border-color: var(--brown);

  &:focus {
    outline: 0;
  }
}

.pickerItemImage,
.pickerItem {
  border-radius: 0.5em;
  overflow: hidden;
}
