@import "~scss/imports";

.element {
  display: grid;
  grid-row-gap: 1rem;
  grid-template-areas:
    "image"
    "name"
    "title"
    "bio";

  margin-top: calc(3 * var(--pad));
  margin-bottom: calc(3 * var(--pad));

  @media (min-width: 480px) {
    grid-template-areas:
      "image name"
      "image title"
      "bio bio";
    grid-template-rows: auto 1fr auto;
    grid-column-gap: 1rem;
    grid-template-columns: auto 1fr;
  }

  @media (min-width: 600px) {
    margin-top: var(--pad);
    margin-bottom: var(--pad);
    grid-template-areas:
      "image name"
      "image title"
      "image bio";
    grid-template-columns: var(--section-columns);
    grid-column-gap: var(--section-gap);
    grid-template-rows: auto auto 1fr;

    .image {
      justify-self: end;
      display: flex;
      justify-content: flex-end;
      align-self: flex-start;
      align-items: flex-start;
    }
  }
}

.name {
  @extend %person-name;
}

.title {
  @extend %subtitle;
  font-style: italic;
  grid-area: title;
}

.bio {
  @extend %body;
  grid-area: bio;
}

.image {
  grid-area: image;
  img {
    @extend %rounded-corners;
    width: 242px;
  }
}
