@import "~scss/imports";

.freeform-row {
  display: flex;
  justify-content: space-between;
  & + .freeform-row {
    margin-top: 1em;
  }
  > * + * {
    margin-left: 1em;
  }
}
.freeform-column {
  width: 100%;
}

.ff-form-success {
  @extend %small-product-title;
  background-color: var(--sky);
  border-radius: 20px;
  padding: 1.5rem;
  margin-bottom: 1em;
  p {
    margin: 0;
  }
}
.ff-form-errors,
.ff-errors {
  background-color: var(--brown);
  border-radius: 5px;
  padding: 0.5em 1em;
  font-weight: bold;
}

.ff-errors {
  list-style-type: none;
}

.freeform-input.ff-has-errors {
  border-color: var(--brown);
}

.freeform-label.freeform-required {
  &:before {
    content: "*";
  }
}
