@import "~scss/imports";

.form {
  --border-radius: 5px;
  @extend %body;
  display: inline-flex;
  border-radius: var(--border-radius);
  > * {
    font: inherit;
    padding: 12px 30px;
  }
  &:hover,
  &:focus-within {
    .input,
    .submit {
      border-color: var(--slate);
    }
  }
}

.formError {
  composes: form;
  .input,
  .submit {
    border-color: var(--brown) !important;
  }
  .submit {
    &:hover,
    &:focus {
      background-color: var(--brown);
    }
  }
}

.input {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  border: solid 2px var(--blue);
  transition: border-color 0.3s;
  width: 100%;

  &:focus,
  &:hover {
    border-radius: var(--border-radius);
    box-shadow: 0 0 1px 0px var(--slate);
    outline: 0;
    position: relative;
    z-index: 1;
    border-color: var(--slate);
  }
}

.submit {
  background-color: var(--white);
  margin-left: calc(-1.5 * var(--border-radius));
  border-radius: var(--border-radius);
  border: solid 2px var(--blue);
  transition: border-color 0.3s;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: 0;
    position: relative;
    z-index: 2;
    box-shadow: 0 0 1px 0px var(--slate);
    border-color: var(--slate);
    transition: background-color 0.3s, color 0.3s;
    background-color: var(--slate);
  }
}

.status {
  margin-top: 1em;
  @extend %body;
  display: flex;
  margin-bottom: 2em;
  a {
    text-decoration: none;
    color: inherit;
    border-bottom: 2px solid;
  }
}

.sending {
}
.success {
  color: var(--black);
  font-weight: bold;
}

.error {
  color: var(--dirt);
  font-weight: bold;
  background-color: var(--brown);
  padding: 1rem;
  border-radius: 5px;
}
