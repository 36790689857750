@import "~scss/imports";

.element {
  p:first-child,
  ul:first-child {
    margin-top: 0;
  }
  p:last-child,
  ul:last-child {
    margin-bottom: 0;
  }
  a {
    color: var(--blue);
    font-weight: bold;
    text-decoration: none;
    border-bottom: 2px solid;
    &:hover,
    &:focus {
      border-bottom-color: transparent;
      color: var(--black);
    }
  }
}
