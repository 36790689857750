%h1 {
  font-family: var(--font-primary);
  font-size: var(--size-h1);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
}

%font-button {
  font-family: var(--font-primary);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
}

%product-title,
%h2 {
  font-family: HelveticaNeue;
  font-size: var(--size-h2);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
}
%h3 {
  font-family: HelveticaNeue;
  font-size: var(--size-h3);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
}
%product-description {
  font-family: HelveticaNeue;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
}

%small-product-title {
  font-family: HelveticaNeue;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
}

%body {
  font-family: HelveticaNeue;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

%subtitle {
  font-family: HelveticaNeue;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

%person-name {
  font-family: HelveticaNeue;
  font-size: var(--size-personname);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

%label {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  text-transform: uppercase;
}

%input-label {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
}

%quote {
  font-family: HelveticaNeue;
  font-size: var(--size-quote);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
}
