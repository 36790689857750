@import "~scss/imports";

.element {
  @extend %verticalblockmargin;
}

.quote {
  @extend %quote;
}

.attribution {
  @extend %body;
  margin-top: 1em;
}
