@import "~scss/imports";
.element {
  background-color: var(--white);
  position: relative;
  z-index: 1;
  @extend %hpad;
  @extend %vpad;
  display: grid;
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  border-top: 2px solid;

  grid-template-areas:
    "subscribe subscribe"
    "address address"
    "additional social"
    "bottom bottom";

  @media (min-width: 800px) {
    grid-template-areas:
      "subscribe subscribe subscribe"
      "address additional social"
      "bottom bottom bottom";
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1000px) {
    grid-template-areas:
      "subscribe address additional social"
      "bottom bottom bottom bottom";
    grid-template-columns: 1fr auto auto auto;

    .bottom {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (min-width: 1400px) {
    grid-template-columns: 2fr 1fr auto auto;
  }
}

.subscribe {
  grid-area: subscribe;
}
.subscribeContact {
  margin-top: 1em;
}
.social {
  grid-area: social;
}
.additional {
  grid-area: additional;
}

.address {
  @extend %body;
  grid-area: address;
}

.links {
  list-style-type: none;
  padding-left: unset;
  margin-top: unset;
  margin-bottom: unset;
  @extend %body;
  a {
    @extend %link-bold-hover;
    @media (min-width: 400px) {
      //white-space: nowrap;
    }
  }
}

.sectionHeading {
  @extend %label;
}

.bottom {
  grid-area: bottom;
  border-top: 2px solid;
  padding-top: 1rem;
  @extend %body;
  a {
    @extend %link-bold-hover;
    &:after {
      content: attr(data-text);
      font-weight: bold;
    }
  }
}
