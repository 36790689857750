@import "~scss/imports";

@import "~scss/imports";

.element {
  @extend %verticalblockmargin;
  @extend %hpad;

  padding-top: var(--pad);
  padding-bottom: var(--pad);
  &:global(.theme--none) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.inner {
  border: 2px solid;
  border-radius: 15px;
  @extend %hpad;
  padding-top: 8vh;
  padding-bottom: 8vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  @extend %h2;
  text-align: center;
  max-width: 50rem;
  &:before {
    content: "“";
  }
  &:after {
    content: "”";
  }
}

.attribution {
  margin-top: 2em;
  &:before {
    content: "– ";
  }
}
