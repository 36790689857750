@import "~scss/imports";

.element {
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  min-height: calc(100vh - var(--nav-height));
  position: sticky;
  top: var(--nav-height);

  opacity: 0;
  animation: fadeIn 1s linear forwards 1s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
