@import "vars";

:root {
  //fonts
  --font-primary: HelveticaNeue, Helvetica, sans-serif;

  // colors
  --black: #1e1e1e;
  --blue: #24394a;
  --water: #49869b;
  --white: #fff;
  --yellow: #f9f2d7;
  --brown: #d1a38d;
  --slate: #b5c1c1;
  --sky: #e1edf9;
  --dirt: #3f3b38;
  --gray: #f3f3f3;
  --red: #ff0000;

  //font-size
  --size-h1: 48px;
  --size-h2: 36px;
  --size-h3: 28px;
  --nav-height: 60px;
  --size-quote: 24px;
  --size-personname: 32px;

  //spacing
  --pad: 4vw;
  --pad2: calc(var(--pad) * 2);
  --pad3: calc(var(--pad) * 2);
  --vmargin: calc(var(--size-h2) * 2);

  --section-text-max: 50em;

  --section-columns: 33vw 1fr;
  --section-gap: 60px;

  @media (min-width: 700px) {
    --size-quote: 32px;
  }

  @media (min-width: 1200px) {
    //--section-columns: 25vw 1fr;
    --nav-height: 100px;
  }

  @media (min-width: $desktop) {
    --size-h1: 64px;
    --size-h2: 48px;
    --size-h3: 36px;
    --size-quote: 40px;
    --size-personname: 40px;
  }

  @media (min-width: 1440px) {
    --pad: 6vw;
    //--vmargin: var(--pad);
    //--section-columns: 33vw 1fr;
  }

  @media (min-width: 1800px) {
    --pad: 8vw;
    //--section-columns: 40% 1fr;
  }
}
