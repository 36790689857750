@import "~scss/imports";

.element {
  @extend %verticalblockmargin;
}

.heading {
  @extend %h2;
  @extend %hpad;
  margin-bottom: 0;
  margin-top: 0;

  & + .cards {
    margin-top: var(--size-h2);
  }
}

.cards {
  &:global(.medium) {
    @extend %cards;
  }
  &:global(.small) {
    @extend %smallcards;
  }
  &:global(.smallDetailed) {
    @extend %hpad;
  }
}
