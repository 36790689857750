@import "~scss/imports";

.element {
  @extend %hpad;
  margin-top: calc(0.5 * var(--pad));
  padding-bottom: var(--pad);
  display: grid;
  grid-template-areas:
    "info"
    "images";

  @media (min-width: 800px) {
    grid-template-areas: "images info";
    grid-template-columns: var(--section-columns);
    grid-column-gap: var(--section-gap);
    grid-template-rows: auto auto 1fr;
  }
}

.id {
  font-style: italic;
  margin-bottom: 1em;
}

.info {
  grid-area: info;
}

.gallery {
  // @media (min-width: 800px) {
  //   .images {
  //     width: 50vw;
  //   }
  // }
}

.zoom {
  @extend %rounded-corners;
}

.max {
  max-width: var(--section-text-max);
}

.heading {
  composes: max;
  grid-area: heading;
  @extend %h1;
  margin-top: 0;
  margin-bottom: 1rem;
}

.subheading {
  composes: max;
  grid-area: subheading;
  font-style: italic;
  @extend %product-description;
}

.text {
  composes: max;
  grid-area: text;
  @extend %product-description;
}

.images {
  grid-area: images;
  // transition: width 1s;
  // width: 40vw;
}
