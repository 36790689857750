@import "vars";
@import "type";

%hpad {
  padding-left: var(--pad);
  padding-right: var(--pad);
}

%vpad {
  padding-top: calc(3 * var(--pad));
  padding-bottom: calc(3 * var(--pad));
  @media (min-width: 768px) {
    padding-top: calc(var(--pad) / 2);
    padding-bottom: calc(var(--pad) / 2);
  }
}

%verticalblockmargin {
  margin-top: var(--vmargin);
  margin-bottom: var(--vmargin);
}

%rounded-corners {
  border-radius: 20px;
  overflow: hidden;
}

%section {
  @extend %hpad;
  @extend %vpad;
  display: grid;
  grid-row-gap: var(--pad);
  align-items: start;

  @media (min-width: 800px) {
    grid-template-columns: var(--section-columns);
    grid-column-gap: var(--section-gap);
  }
}

%section-pinned {
  @extend %section;
  @media (min-width: 800px) {
    > header,
    > .heading {
      align-self: stretch;
      max-height: calc(100vh - var(--nav-height));
      position: sticky;
      top: var(--nav-height);
    }
  }
}

%cards {
  display: grid;
  grid-gap: 4rem;
  @extend %hpad;
  @extend %verticalblockmargin;

  @media (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }
}
%smallcards {
  display: grid;
  grid-gap: 4rem;
  @extend %hpad;
  @extend %verticalblockmargin;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

  @media (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;
  }
}

%button {
  background-color: unset;
  cursor: pointer;
  @extend %font-button;
  border: 2px solid;
  border-radius: 5rem;
  padding: 1rem 2rem;
  color: inherit;
  text-decoration: unset;
  display: inline-flex;
  @extend %button-hover;
}

%button-hover {
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  &:hover,
  &:focus {
    outline: 0;
    background-color: var(--black);
    border-color: var(--black);
    color: var(--white);
  }
}

%link-not-underline-hover {
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid;
  transition: border-color 0.3s;
  &:hover {
    border-bottom-color: transparent;
  }
}

%link-underline-hover {
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s;

  &:hover {
    border-bottom-color: currentColor;
  }
}

%link-bold-hover {
  color: inherit;
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;

  &:after {
    content: attr(data-text);
    font-weight: bold;
    height: 0;
    visibility: hidden;
    user-select: none;
    pointer-events: none;
  }
  &:hover,
  &:focus {
    font-weight: bold;
    outline: 0;
  }
}
