@import "~scss/imports";

.item {
  @extend %hpad;
  @extend %verticalblockmargin;

  &:first-child {
    padding-top: 0;
    &:before {
      content: unset;
    }
  }
  //margin-top: calc(2 * var(--pad));
  display: grid;
  grid-template-areas:
    "heading"
    "image"
    "text"
    "link";
  grid-gap: var(--pad);
  justify-items: start;
  padding-top: var(--pad);
  position: relative;
  &:before {
    content: "";
    border-top: 2px solid;
    display: block;
    width: calc(100% - 2 * var(--pad));
    position: absolute;
    top: -2px;
    left: var(--pad);
  }

  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--pad);
    grid-template-areas:
      "heading image"
      "text image"
      "link image";
    grid-template-rows: auto auto 1fr;
    grid-row-gap: 1rem;
    align-items: start;

    &:nth-child(even) {
      grid-template-areas:
        "image heading"
        "image text"
        "image link";
    }
  }
}

.heading {
  @extend %h2;
  margin-top: 0;
  margin-bottom: 0;
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: auto 1fr;
  grid-area: heading;
  align-items: center;
}
.icon {
  width: 60px;
}
.image {
  grid-area: image;
  img {
    border-radius: 20px;
  }
}

.text {
  @extend %body;
  grid-area: text;
}

.link {
  @extend %button;
  grid-area: link;
  align-items: center;
}

.card {
  a {
    padding: 2rem;

    @extend %rounded-corners;

    display: grid;
    grid-template-areas:
      "heading heading"
      "icon arrow";
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    color: inherit;
    text-decoration: none;
    min-height: 15em;
    transition: border-radius 0.3s;

    &:hover,
    &:focus {
      border-radius: 35px;
      .cardArrow {
        transform: translateX(-10%);
      }
    }
  }
}

.smallCards {
  @extend %cards;
  .card {
    &:nth-child(1n) > a {
      background-color: var(--sky);
    }
    &:nth-child(2n) > a {
      background-color: var(--brown);
    }
    &:nth-child(3n) > a {
      background-color: var(--slate);
    }
    &:nth-child(4n) > a {
      background-color: var(--yellow);
    }
    &:nth-child(5n) > a {
      background-color: var(--dirt);
      color: var(--white);
    }
  }
}

.cardArrow {
  grid-area: arrow;
  width: 3em;
  height: 3em;
  transition: transform 0.3s;
}

.cardHeading {
  @extend %h3;
  margin-top: 0;
  margin-bottom: 0;
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: auto 1fr;
  grid-area: heading;
}
.cardIcon {
  composes: icon;
  grid-area: icon;
}
