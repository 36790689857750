@import "~scss/imports";

.element {
  &:global(.theme--none) {
    &:before,
    &:after {
      display: none;
    }
    @extend %verticalblockmargin;
    .inner {
      padding-top: 4vh;
      padding-bottom: 4vh;
    }
  }

  &:before,
  &:after {
    content: "";
    display: block;
    height: 29px;
  }
  &:before {
    border-bottom: 2px solid;
  }
  &:after {
    border-top: 2px solid;
  }
}

.inner {
  @extend %hpad;
  padding-top: 8vh;
  padding-bottom: 8vh;
  max-width: 50em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  @extend %h2;
  text-align: center;
}

.link {
  margin-top: 2em;
}
