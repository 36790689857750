@import "~scss/imports";

.element {
  @extend %section-pinned;

  .text {
    max-width: var(--section-text-max);
  }
}

.heading {
  @extend %h2;
  margin-top: unset;
  margin-bottom: unset;
}

.body {
  > * {
    margin-top: 0 !important;
  }
}
