@import "~scss/imports";

.element {
  @extend %hpad;

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-template-areas: "header subnav" "body subnav";
    grid-column-gap: var(--pad);

    .subnav {
      position: sticky;
      top: calc(var(--nav-height) + var(--pad));
      max-height: calc(100vh - var(--nav-height));
      align-self: start;
    }
    .subnavList {
      //margin-right: var(--pad);
    }
  }
}

.header {
  //@extend %section;

  grid-area: header;
}

.heading {
  @extend %h2;
  margin-top: var(--pad);
  margin-bottom: var(--pad);
}

.subnav {
  //@extend %hpad;
  margin-bottom: var(--pad);
  grid-area: subnav;
}

.subnavList {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
  border: 2px solid;
  padding: 2rem;
  border-radius: 2rem;

  a {
    color: inherit;
    text-decoration: none;

    @extend %body;
    &:hover,
    &:focus {
      border-bottom: 2px solid;
      outline: 0;
    }
  }
  li + li {
    margin-top: 1em;
  }
}

.body {
  grid-area: body;
}
