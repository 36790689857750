@import "~scss/imports";

.hero {
  //@extend %hpad;
  padding-top: 2rem;
  //padding-bottom: 2rem;
}

.image {
  @extend %hpad;
}
.imageInner {
  @extend %rounded-corners;
}

.heroText {
  @extend %hpad;
  @extend %section-pinned;
}

.heading {
  @extend %h2;
  display: grid;
  grid-template-rows: auto 1fr;
  //align-items: center;
  grid-gap: 2rem;
  //gap: 2rem;
  margin-top: 0;
  margin-bottom: 0;
}

.section {
  @extend %section;
}

.icon {
  width: 60px;
}

.text {
  @extend %body;
  //margin-top: var(--pad);
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
