@import "variables";

html {
  box-sizing: border-box;
  color: var(--black);
  scroll-behavior: smooth;
}
* {
  box-sizing: inherit;
}

.theme--blue {
  background-color: var(--blue);
  color: var(--white);
}
.theme--yellow {
  background-color: var(--yellow);
}
.theme--brown {
  background-color: var(--brown);
}
.theme--slate {
  background-color: var(--slate);
}
.theme--sky {
  background-color: var(--sky);
}
.theme--dirt {
  background-color: var(--dirt);
  color: var(--white);
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
