@import "~scss/imports";

.element {
  align-items: center;
  display: flex;
  justify-content: space-between;
  @extend %hpad;

  position: sticky;
  top: 0;
  background-color: var(--white);
  height: var(--nav-height);
  z-index: 101;

  @media (min-width: 550px) {
    .desktopLinks > a {
      height: 100%;
    }
    .logo {
      height: 100%;
      width: auto;
      max-width: 400px;
    }
  }
}

.desktopLogoLink {
  width: 100%;
  height: auto;
  max-width: 477px;
}

.desktopPhoneNumber {
  position: absolute;
  top: 0;
  right: var(--pad);
  width: auto;
  height: auto;
  background-color: var(--slate);
  color: inherit;
  @extend %body;
  padding: 3px 1.2em;
  border-bottom-left-radius: 1.5em;
  border-bottom-right-radius: 1.5em;
  text-decoration: none;
}

.elementMobile {
  composes: element;
  padding-top: 10px;
  padding-bottom: 10px;
}

.elementDesktop {
  composes: element;
  padding-top: 36px;
  padding-bottom: 36px;

  .desktopLogoLink {
    margin-top: 1.4em;
  }
}

.logo {
  display: block;
}

.links {
  font-family: HelveticaNeue;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  flex-shrink: 0;
  list-style-type: none;
  margin-top: 1.5em;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;

  a {
    @extend %link-underline-hover;
  }
}

.desktopLinks {
  composes: links;

  > a {
    width: 100%;
    height: auto;
    max-width: 477px;
  }

  li {
    margin-left: 4vw;
  }
  a:global(.active) {
    border-bottom-color: currentColor;
  }
}
.mobileLinks {
  composes: links;
  font-size: 28px;

  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 5rem;
  margin-top: 0;

  background-color: var(--blue);
  color: var(--white);
  flex-direction: column;
  li + li {
    margin-top: 1em;
  }
}

.buttonSpacer {
  flex-shrink: 0;
  width: 36px;
  height: 32px;
  margin-left: 4rem;
  position: relative;
  z-index: -1;
}

.menuToggle {
  position: fixed;
  top: calc(var(--nav-height) / 2);
  transform: translate(0, -50%);
  right: var(--nav-pad);
  z-index: 11;
  background-color: transparent;
  border: 0;
  padding: 5px;
  cursor: pointer;

  svg {
    display: block;
  }
  line {
    transform-origin: center center;
    transition: transform 0.3s;
  }
}

.menuToggleOpen {
  composes: menuToggle;

  svg {
    line {
      stroke: var(--white);
    }
    line:nth-child(2) {
      opacity: 0;
    }
    line:nth-child(1) {
      transform: translate(-24%, 33%) rotate(45deg);
    }
    line:nth-child(3) {
      transform: translate(-24%, -30%) rotate(-45deg);
    }
  }
}

.menuToggleClosed {
  composes: menuToggle;
}
