@import "~scss/imports";

.element {
  @extend %hpad;
  display: flex;
  flex-direction: column;
}

.heading {
  @extend %h2;
  margin-top: var(--pad);
}

.cards {
  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
}
.cardsThree {
  @extend %cards;
  margin-top: var(--pad);
  margin-bottom: var(--pad);
}

.loadMore {
  @extend %button;
  margin-bottom: var(--pad);
  margin-left: auto;
  margin-right: auto;
}

.cardLoader {
  height: 0;
  border: 2px solid var(--blue);
  padding-bottom: 116%;
  border-radius: 15px;
  overflow: hidden;
}

.card {
  @media (min-width: 800px) {
    min-height: 550px;
  }
  display: flex;
  a {
    background-color: var(--gray);
    display: flex;
    flex-direction: column;
    color: inherit;
    text-decoration: none;
    border-radius: 15px;
    width: 100%;
    overflow: hidden;
    transition: border-radius 0.3s;

    &:hover,
    &:focus {
      border-radius: 25px;

      .cardCta svg {
        transform: translateX(-10%);
      }
    }

    &:global(.red) {
      background-color: var(--brown);
    }
    &:global(.blue) {
      background-color: var(--sky);
    }
    &:global(.yellow) {
      background-color: var(--yellow);
    }
  }
}

.cardInner {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardHeading {
  @extend %small-product-title;
  margin-top: 1rem;
  margin-bottom: 1em;
}

.cardDate {
  @extend %body;
}

.cardCta {
  @extend %body;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  svg {
    transition: transform 0.3s;
  }
}
