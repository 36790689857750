@import "~scss/imports";

.element {
  &:before {
    border-top: 2px solid;
    content: "";
    display: block;
    //width: calc(100% - (2 * var(--pad)));
    //margin-left: var(--pad);
  }

  &:nth-child(4n + 1) {
    .item {
      background-color: var(--sky);
    }
  }
  &:nth-child(4n + 2) {
    .item {
      background-color: var(--yellow);
    }
  }
  &:nth-child(4n + 3) {
    .item {
      background-color: var(--brown);
    }
  }
  &:nth-child(4n + 4) {
    .item {
      background-color: var(--slate);
    }
  }
}

.anchor {
  position: relative;
  top: calc(-1 * var(--nav-height) - 2px);
  pointer-events: none;
}

.inner {
  //@extend %section;
  padding-top: 30px;
}

.heading {
  @extend %h3;
  margin-top: 0;
  margin-bottom: 0;
}

.list {
  margin: 0;
  list-style-type: none;
  padding-left: var(--pad);
  @extend %body;
}

.item {
  border: 2px solid;
  border-radius: 20px;
  font: inherit;

  &:focus-within .question svg {
    background-color: var(--gray);
    border-radius: 50%;
  }

  margin-top: 2em;
  margin-bottom: 2em;
  // & + .item {
  //   margin-top: 2em;
  // }
  .question,
  .answer {
    padding: 1em;
    border-radius: 20px;
  }
}

.question {
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font: inherit;
  padding: 0;
  font-style: italic;

  &:focus,
  &:hover {
    outline: 0;
    //background-color: var(--gray);
  }

  svg {
    width: 30px;
    height: 30px;
  }
}

.answer {
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
