@import "~scss/imports";

.element {
  @extend %hpad;
  @extend %vpad;
}
.heading {
  @extend %h2;
  margin-top: unset;
  margin-bottom: unset;
}
.list {
  list-style-type: none;
  padding-left: unset;
  margin-bottom: unset;
}
