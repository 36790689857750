@import "~scss/imports";

.element {
  @extend %section;

  label {
    margin-bottom: 1em;
    @extend %input-label;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    display: block;
    border-radius: 5px;
    font-size: 100%;
    border-width: 2px;
    border-style: solid;
    padding: 0.8rem 1rem;
    width: 100%;
    @extend %body;

    &:focus {
      border-color: var(--blue);
    }
  }

  button {
    @extend %button;
    margin-top: 2rem;
  }
}

.heading {
  @extend %h2;
  margin-top: 0;
}

.text {
  @extend %body;
}

.inner {
  max-width: 842px;
}

.form {
  margin-top: var(--pad);
}
