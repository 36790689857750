@import "~scss/imports";

.element {
  @extend %hpad;
  padding-top: 1rem;
  padding-bottom: 4rem;

  display: grid;
  grid-row-gap: 2rem;
  grid-template-areas:
    "image"
    "heading"
    "link";
  align-items: start;

  @media (min-width: $desktop) {
    padding-top: 58px;
    padding-bottom: 58px;
  }

  @media (min-width: $desktop) {
    grid-template-columns: 55% 1fr;
    grid-column-gap: 3vw;
    grid-template-areas:
      ". image"
      "heading image"
      "link image"
      ". image";
    grid-template-rows: 1fr auto auto 1fr;
    padding-top: 66px;
    padding-bottom: 66px;
  }
}

.heading {
  grid-area: heading;
  @extend %h1;
}

.image {
  grid-area: image;
  @extend %rounded-corners;
}

.link {
  @extend %button;
  display: block;
  grid-area: link;
  justify-self: start;
}
