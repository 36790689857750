@import "~scss/imports";

.element {
  @extend %section-pinned;
  padding-top: 0;

  header,
  main {
    padding-top: var(--pad);
  }
}

.heading {
  @extend %h2;
}

.date {
  @extend %body;
}

.related {
  padding-left: var(--pad);
  padding-right: var(--pad);
}
.relatedInner {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 2px solid;
}
.relatedHeading {
  @extend %h2;
  margin-top: 0;
}
.relatedCards {
  display: grid;
  grid-gap: 3rem;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .relatedInner {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 3rem;
  }
}
