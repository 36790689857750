@import "~scss/imports";

.element {
  height: 100%;
  display: flex;

  &:global(.large) {
    @extend %verticalblockmargin;
    @extend %hpad;

    // margin-top: var(--pad3);
    //   margin-bottom: var(--pad3);

    // & + .element {
    //   margin-top: var(--pad3);
    //   margin-bottom: var(--pad3);
    // }

    .title {
      @extend %product-title;
    }
  }

  &:global(.medium),
  &:global(.small) {
    .title {
      @extend %small-product-title;
    }
  }

  &:global(.smallDetailed) .title {
    @extend %product-title;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover .image {
      border-radius: 30px;
    }
  }
  .inner {
    display: grid;
    grid-row-gap: 2rem;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      "image"
      "text"
      "link";
    height: 100%;
  }

  @media (min-width: 800px) {
    .inner {
      display: grid;
      grid-column-gap: 5vw;
      grid-template-rows: auto 1fr;
    }
    &:global(.left) .inner {
      grid-template-columns: 1fr 50%;
      grid-template-areas:
        ". image"
        "text image"
        "link image"
        ". image";
    }
    &:global(.right) .inner {
      grid-template-columns: 50% 1fr;
      grid-template-areas:
        "image ."
        "image text"
        "image link"
        "image .";
    }
    &:global(.smallDetailed) .inner {
      grid-template-columns: var(--section-columns);
      grid-template-areas: "image text";

      .image {
        max-width: 350px;
        margin-left: auto;
      }

      .text {
        grid-template-rows: auto auto auto 1fr;
      }
    }
  }
}

.id {
  font-style: italic;
}

.text {
  grid-area: text;
  display: grid;
  grid-row-gap: 1rem;
}

.title {
}

.shortDescription {
  @extend %product-description;
  font-style: italic;
}
.richText {
  @extend %body;
}

.link {
  grid-area: link;
  @extend %button;
  justify-self: start;
  align-self: end;
}

.image {
  grid-area: image;
  @extend %rounded-corners;
  align-self: center;
  transition: border-radius 0.3s;
}
