@import "~scss/imports";

.list {
  list-style-type: none;

  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: 600px) {
    column-count: 2;
    column-gap: 2em;
  }
}
.item {
  break-inside: avoid;
  & + .item {
    margin-top: 1em;
  }
}

.heading {
  @extend %body;
  text-transform: uppercase;
}

.text {
  @extend %body;
}
