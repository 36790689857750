@import "~scss/imports";

.element {
  @extend %verticalblockmargin;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
}

.inner {
  position: relative;
  height: 0;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
  }
}